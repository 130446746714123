<template>
  <div class="Login">
    <div class="Logo">
      <img
        src="@/assets/svg/logos/milva-logo-green.svg" />
      <span
        class="DevLabel"
        v-if="isDev">Development</span>
    </div>

    <div
      v-if="isDemoPeriodExpired"
      class="Notice">
      <p>
        <b>{{ mixWB('YOUR_ACCESS_HAS_EXPIRED') }}</b>
      </p>
      <p v-html="demoInfoText"/>
    </div>
    <span
      v-else
      class="LoginText">{{ mixWB('SIGN_IN_TO_YOUR_ACCOUNT') }}</span>

    <DeprecatedInfo />

    <div class="LoginFormWrap">
      <LoginForm v-if="!showForgotPassword" />
      <ForgotPasswordForm v-else />
      <span
        class="ForgotPasswordText"
        @click="toggleForgotPassword">
        {{ showForgotPassword ? mixWB('LOGIN_LINK') : mixWB('FORGOT_PASSWORD_LINK') }}
      </span>
    </div>

    <!-- Version -->
    <span class="PackageVersion">{{ getPackageVersionText() }}</span>

    <!-- Lang select button -->
    <div
      v-if="!isLoggedIn"
      class="LanguageSelect"
      @click="onLangSelectClick">
      <div class="LangIcon">
        <FlagIcon :countryCode="selectedLanguage.countryCode" />
      </div>
      <span class="LangTitle">{{ selectedLanguage.countryCode }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'
import LoginForm from '@/components/Forms/LoginForm.vue'
import ForgotPasswordForm from '@/components/Forms/ForgotPasswordForm.vue'
import { keys } from '@/globals/javascript/_util/keys'
import FlagIcon from '@/components/FlagIcon.vue'
import DeprecatedInfo from '@/components/DeprecatedInfo.vue'

export default {
  name: 'Login',
  data() {
    return {
      showForgotPassword: false,
      isDemoPeriodExpired: false,
    }
  },
  computed: {
    ...mapGetters([
      'packageVersion',
      'selectedLanguage',
      'isLoggedIn',
      'isDev',
    ]),
    demoInfoText() {
      const email = '<a href="mailto:sales@milva.dk">sales@milva.dk</a>'
      const phone = '<a href="tel:30201070">30 20 10 70</a>'

      return this.mixWB('ACCESS_EXPIRED_INFO_TEXT', [phone, email])
    },
  },
  methods: {
    onUserLogin() {
      this.$router.push({ name: 'AllScreenings' })
    },
    onLangSelectClick() {
      EventBus.$emit('show-lang-select')
    },
    onLangClick(lang) {
      window.localStorage.setItem(keys.LS_SAVED_LOCALE, lang.locale)
      window.location.reload()
    },
    getPackageVersionText() {
      return `${ this.mixWB('VERSION_X', [this.packageVersion]) } @ ${ process.env.NODE_ENV }`
    },
    toggleForgotPassword() {
      this.showForgotPassword = !this.showForgotPassword
    },
    checkForDemoExpired() {
      const { reason } = this.$route.query
      this.isDemoPeriodExpired = reason === 'demo-expired'
    },
  },
  components: {
    LoginForm,
    ForgotPasswordForm,
    FlagIcon,
    DeprecatedInfo,
  },
  created() {
    EventBus.$on('user-logged-in', this.onUserLogin)
    this.checkForDemoExpired()
  },
  destroyed() {
    EventBus.$off('user-logged-in', this.onUserLogin)
  },
}
</script>

<style lang="stylus" scoped>
  .Login
    min-height 600px

  >>> .DeprecatedInfo
    width 100%
    max-width 80%
    margin-bottom 30px
    margin-top 0

  .LoginFormWrap
    width 100%
    max-width 80%
    margin-bottom 30px
    .ForgotPasswordText
      text-align center
      font-size 0.875rem
      cursor pointer

  .Login
    width 100%
    height 100vh
    display flex
    flex-direction column
    align-items center
    padding-top 100px

  .Logo
    max-width 150px
    margin-bottom 30px
    margin-left 30px

  .Notice
    width 100%
    max-width 350px
    margin-bottom 20px
    p
      text-align center
    a
      text-decoration underline
    +below($phablet)
      max-width 80%

  .LoginText
    margin-bottom 30px

  .LanguageSelect
    position absolute
    top 10px
    right 10px
    display flex
    align-items center
    padding 6px
    border-radius 8px
    cursor pointer
    transition: all 0.15s ease;
    .LangIcon
      circle(20px)
      overflow hidden
      opacity 0.55
      transition all 0.15s ease
    .LangTitle
      font-size 0.75rem
      margin-left 4px
    &:hover
      background-color $color_grey_lightest
      .LangIcon
        opacity 1

  .PackageVersion
    position absolute
    bottom 20px
    font-size 0.875rem
    line-height 1
    color $color_grey

  .DevLabel
    font-size 14px
    font-weight 700
    text-transform uppercase

</style>

<style lang="stylus">
  .PageSignIn
    padding-bottom 0

  .Notice
    a
      text-decoration underline
</style>
