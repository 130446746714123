<template>
  <form
    class="ForgotPasswordForm"
    @submit.prevent="onForgotPassword">
    <span
      v-if="successMessage"
      class="SuccessMessage">{{ mixWB('FORGOT_PASSWORD_SUCCESS_MESSAGE') }}</span>
    <template v-else>
      <InputField
        icon="user"
        name="email"
        type="email"
        :disableAutoSuggest="true"
        :placeholder="mixWB('EMAIL')"
        :value="email"
        @on-update='onFieldUpdate' />
      <span
        class="ErrorText"
        v-if="errorText">{{ errorText }}</span>
      <Button
        v-else
        type="submit"
        :text="mixWB('RESET_PASSWORD')"
        :isLoading="isLoading" />
    </template>

    <Dialog
      :isShowing="showModal"
      :useComponent="NoficationModal"
      :componentProps="{
        icon: '',
        texts: [
          mixWB('DEMO_USER_PASSWORD_RESET_LINE_1'),
          mixWB('DEMO_USER_PASSWORD_RESET_LINE_2'),
        ],
      }"
      @close="showModal = false" />
  </form>
</template>

<script>
import { auth } from '@/firebase/init'
import InputField from '@/components/FormElements/InputField.vue'
import Button from '@/components/Buttons/Button.vue'
import { getErrorMessage } from '@/firebase/errors'
import { mapGetters } from 'vuex'
import Dialog from '@/components/Modals/Dialog.vue'
import NoficationModal from '@/components/Modals/NoficationModal.vue'

export default {
  data() {
    return {
      email: '',
      errorText: '',
      isLoading: false,
      successMessage: false,
      // Modal
      NoficationModal,
      showModal: false,
    }
  },
  computed: {
    ...mapGetters([
      'allUsers',
    ]),
  },
  methods: {
    onForgotPassword() {
      if (this.isLoading) return

      this.isLoading = true
      this.successMessage = false

      // Check for demo user
      if (this.email.includes('milva-demo.dk')) {
        this.showModal = true
        this.isLoading = false
        return
      }

      auth.sendPasswordResetEmail(this.email)
        .then(() => {
          this.isLoading = false
          this.successMessage = true
        })
        .catch((err) => {
          this.isLoading = false
          this.errorText = getErrorMessage(err.code, err.message)
        })
    },
    onFieldUpdate(field) {
      this.errorText = ''
      this[field.name] = field.value
    },
  },
  components: {
    InputField,
    Button,
    Dialog,
  },
}
</script>

<style lang="stylus" scoped>
  .ErrorText
    display block
    margin-bottom 10px
    color $color_error
    font-size 0.875rem
  .SuccessMessage
    font-size 1.25rem
    color $color_primary
    text-align center
</style>
